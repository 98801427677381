import React, { Component } from "react";
import Header from "./Components/Header/Header";
import WithDimensions from "./Components/WithDimensions/WithDimensions";
import Title from "./Components/Title/Title";

export default class App extends Component {
  render() {
    return (
      <div>
        <Title></Title>
        <WithDimensions>
          <Header />
        </WithDimensions>
      </div>
    );
  }
}
