import React, { Component } from "react";
import ContainerDimensions from "react-container-dimensions";

export default class WithDimensions extends Component {
  render() {
    return (
      <ContainerDimensions>
        {({ width, height }) => (
          <div>
            {React.cloneElement(this.props.children, {
              height,
              width
            })}
          </div>
        )}
      </ContainerDimensions>
    );
  }
}
