import React, { Component } from "react";
import PititaRectangular from "./Images/pititaRectangular.jpeg";
import PititaSquared from "./Images/pititaSquared.jpeg";

import WithDimentions from "../WithDimensions/WithDimensions";
import "./header.css";
export default class Header extends Component {
  render() {
    return (
      <WithDimentions>
        <div style={{ textAlign: "center" }}>
          <img
            src={this.props.width > 600 ? PititaRectangular : PititaSquared}
            alt=""
          />
          <p>
            Resolucion de pantalla de {Math.round(this.props.width)} pixeles
          </p>
        </div>
      </WithDimentions>
    );
  }
}
