import React, { Component } from "react";
import "./title.css";
export default class Title extends Component {
  render() {
    return (
      <div>
        <div className="title red lighten-2 center-align">
          <p className="cord">La</p>
        </div>
        <div className="title yellow lighten-2 center-align">
          <p className="cord">Pitita</p>
        </div>
        <div className="title green lighten-2 center-align">
          <p className="cord">Web</p>
        </div>
      </div>
    );
  }
}
